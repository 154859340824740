<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h4 class="card-title ticker">
            {{ company.company_name }}
            <i v-if="!isLoaded" class="fa fa-gear fa-spin"></i>
          </h4>
        </template>
      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import Timeline from "../pages/Vnud-Timeline.vue";

export default {
  components: {},
  created() {
    const queryParams = this.$route.query;
    this.loadData(queryParams.name);
  },
  setLoading() {},
  data() {
    return {
      company: this.getDefaultCompany(),
      isLoaded: false,
    };
  },
  watch: {
    "$route.query": {
      handler(newQuery, oldQuery) {
        console.log(" Reload since it changed ");
        const queryParams = this.$route.query;
        this.loadData(queryParams.name);
      },
      deep: true, // Allows deep watching of nested properties
      immediate: true, // If you want the handler to be called immediately
    },
  },
  methods: {
    getDefaultCompany() {
      return {
        company_name: "Loading...",
        exchange_tickers: [],
      };
    },

    async loadData(company_name) {
      this.isLoaded = false;
      globalController.api_call(
        "/company/query?company_name=" + encodeURIComponent(company_name),
        (result) => {
          this.isLoaded = true;
          this.company = result.companies[0];
          console.log("Company result: Result ");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
};
</script>
<style></style>
