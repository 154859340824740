<template>
  <vnud-card>
    <template v-slot:header>
      <h5 class="title">Edit Profile</h5>
    </template>
    <form>
      <div class="row">
        <div class="col-md-3">
          <fg-input type="text" :disabled="true" label="Username" placeholder="Username" v-model="user.username">
          </fg-input>
        </div>
        <div class="col-md-5">
          <fg-input type="text" label="Company" placeholder="Paper dashboard" v-model="user.company"
            @blur="updateUserField('company', $event.target.value)"
            @keydown.enter="updateUserField('company', $event.target.value)">
          </fg-input>
        </div>

        <div class="col-md-4">
          <fg-input type="email" label="Email" placeholder="Email" v-model="user.email"
            @blur="updateUserField('email', $event.target.value)"
            @keydown.enter="updateUserField('email', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <fg-input type="text" label="First Name" placeholder="First Name" v-model="user.first_name"
            @blur="updateUserField('first_name', $event.target.value)"
            @keydown.enter="updateUserField('first_name', $event.target.value)">
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input type="text" label="Last Name" placeholder="Last Name" v-model="user.last_name"
            @blur="updateUserField('last_name', $event.target.value)"
            @keydown.enter="updateUserField('last_name', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input type="text" label="Address" placeholder="Home Address" v-model="user.address"
            @blur="updateUserField('address', $event.target.value)"
            @keydown.enter="updateUserField('address', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <fg-input type="text" label="City" placeholder="City" v-model="user.city"
            @blur="updateUserField('city', $event.target.value)"
            @keydown.enter="updateUserField('city', $event.target.value)">
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input type="text" label="Country" placeholder="Country" v-model="user.country"
            @blur="updateUserField('country', $event.target.value)"
            @keydown.enter="updateUserField('country', $event.target.value)">
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input label="Postal Code" placeholder="ZIP Code" v-model="user.postal_code"
            @blur="updateUserField('postal_code', $event.target.value)"
            @keydown.enter="updateUserField('postal_code', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input label="About Me">
            <textarea class="form-control" placeholder="About me" v-model="user.about_me"
              @blur="updateUserField('about_me', $event.target.value)"
              @keydown.enter="updateUserField('about_me', $event.target.value)">
        </textarea>
          </fg-input>
        </div>
      </div>
    </form>

  </vnud-card>

  <vnud-card>
    <template v-slot:header>
      <h5 class="title">Edit Settings</h5>
    </template>
    <form>
      <div class="row">
        <div class="col-md-2">
          <fg-input label="See debug">
            <br>
            <n-switch v-model="user.my_debug_interface" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_debug_interface', user.my_debug_interface)"></n-switch>
          </fg-input>
        </div>
        <div class="col-md-2">
          <fg-input label="Developer UI">
            <br>
            <n-switch v-model="user.my_dev_interface" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_dev_interface', user.my_dev_interface)"></n-switch>
          </fg-input>          
        </div>
      </div>
    </form>
  </vnud-card>

</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  Switch,
} from "@/components";

export default {
  components: {
    [Switch.name]: Switch,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("PROFILE: Mounted");
    this.$store.dispatch("fetchUser");
  },
  methods: {
    ...mapActions(["updateUserKey"]),
    updateUserField(key, value) {      
      this.updateUserKey({ key, value });
    },
  },
};
</script>
<style></style>
