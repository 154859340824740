<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <div class="row pull-right">
            <div class="col-sm-10 checkbox-radios">
              <checkbox :disabled="!watchlistLoaded" v-model="checkboxes.watching"
                >Watch this stock</checkbox
              >
            </div>
          </div>

          <h4 class="card-title ticker">
            {{ ticker }} <i v-if="!isLoaded" class="fa fa-gear fa-spin"></i>
          </h4>
        </template>
        <div>
          <div class="row">
            <label class="col-sm-2 col-form-label"
              ><h5>
                <a v-if="info.website" :href="info.website" target="_blank">{{
                  info.longName
                }}</a>
              </h5></label
            >
            <div class="col-sm-10">
              <span class="form-text">{{ info.industry }}</span>
              <span class="form-text"></span>
              <span class="form-text">{{ info.address1 }}</span>
              <p />
              <span class="form-text">{{ info.longBusinessSummary }}</span>
              <p />
              <a v-if="info.website" :href="info.website" target="_blank">Website</a>
            </div>
          </div>
        </div>
      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import { Checkbox, Radio } from "@/components/index";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import Timeline from "../pages/Vnud-Timeline.vue";

export default {
  components: {
    Checkbox,
  },
  props: {
    ticker: String,
  },
  created() {
    //debugger;
    //this.loadData(this.ticker);
  },
  data() {
    return {
      name: "loading...",
      isLoaded: false,
      exchange: "",
      watchlistLoaded: false,
      info: this.setDefaultTickerInfo(),

      default_watchlist: null,
      checkboxes: {
        watching: false,
      },
    };
  },
  watch: {
    "checkboxes.watching": {
      handler(newValue, oldValue) {
        let extic = this.exchange + ":" + this.ticker;

        if (!this.default_watchlist) {
          debugger;
          return;
        }

        let is_on_list = this.default_watchlist.exchange_tickers.includes(extic);
        if (is_on_list == newValue) return;

        const action = newValue ? "append" : "remove";
        const apicall = `/ticker/user/watchlist/${action}/default/${this.exchange}:${this.ticker}`;

        globalController.api_call(
          apicall,
          (result) => {
            console.log("Ok");
          },
          (error) => {
            console.log("Failed adding to watchlist");
            utils.runToast(
              ` ${error} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
          }
        );
      },
    },
    ticker: {
      immediate: true, // Executes the watcher immediately on component mount
      handler(newValue, oldValue) {
        this.name = "...loading...";
        this.setDefaultTickerInfo();
        this.loadData(newValue);
      },
    },
  },
  methods: {
    setDefaultTickerInfo() {
      this.info = {
        longName: "loading...",
        website: "",
      };
    },
    async loadData(ticker) {
      this.isLoaded = false;
      this.watchlistLoaded = false;
      this.checkboxes.watching = false;

      globalController.api_call(
        "/ticker/get_info?ticker=" + encodeURIComponent(ticker),
        (result) => {
          this.isLoaded = true;
          this.info = result.info;
          this.name = this.info.longName;

          this.exchange = this.info.exchange;
          this.loadUserWatchlist("default");
          console.log("Company result: Result");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    async loadUserWatchlist(listname) {
      globalController.api_call(
        "/ticker/user/watchlist/get/" + listname,
        (result) => {
          this.default_watchlist = result;
          this.watchlistLoaded = true;

          let extic = this.exchange + ":" + this.ticker;
          this.checkboxes.watching = result.exchange_tickers.includes(extic);
          console.log("User result: Result");
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },
  },
};
</script>
<style></style>
