import { Modal } from "@/components";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";

const GlobalUtil = {
    updateQueryParameter(url, key, value) {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        params.set(key, value);
        urlObj.search = params.toString();
        return urlObj.toString();
    },
    setLocalStorageItem(key, value) {
        localStorage.setItem(key, value);
        const event = new Event('localStorageChange');
        window.dispatchEvent(event);
    },
    getLocalStorageUser() {
        const userData = localStorage.getItem("userData");
        if (userData && userData != "undefined") {
            return JSON.parse(userData);
        }

        return null;
    },
    runToast(html, icon, pos, type, ownText, ownIcon) {
        if (!icon)
            icon = "now-ui-icons ui-1_bell-53";

        const content = {
            component: Notification,

            props: {
                ownText: ownText,
                ownIcon: ownIcon,
                icon: icon,
                text: html,
                type: type,
            },
        };
        const toast = useToast();
        toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: pos,
        });
    },
}

export default GlobalUtil;