<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-primary">
                      <i class="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="15"></animated-number>
                    </h3>
                    <h6 class="stats-title">Portfolio Companies</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <i class="now-ui-icons business_money-coins"></i>
                    </div>
                    <h3 class="info-title">
                      <small>$</small>
                      <animated-number :value="3521"></animated-number>
                    </h3>
                    <h6 class="stats-title">Today's Revenue</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-info">
                      <i class="now-ui-icons users_single-02"></i>
                    </div>
                    <h3 class="info-title">
                      <small>$</small>
                      <animated-number :value="1372252"></animated-number>
                    </h3>
                    <h6 class="stats-title">Total Assets</h6>
                  </div>
                </div>
              </div>
              <!--
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-danger">
                      <i class="now-ui-icons objects_support-17"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="353"></animated-number>
                    </h3>
                    <h6 class="stats-title">Support Requests</h6>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <TradingTickers ></TradingTickers>
  </div>
</template>
<script>

import TradingTickers from "@/views/dashboard/trading/TradingTickers.vue";

import {
  VnudCard,
  Table as NTable,
  AnimatedNumber,
  Progress as NProgress,
  AsyncWorldMap,
} from "@/components";

import {
  activeUsersChart,
  emailsCampaignChart,
  activeCountriesChart,
} from "@/components/Charts/LineChart";

export default {
  components: {
    VnudCard,
    NTable,
    AnimatedNumber,
//    NProgress,
//    AsyncWorldMap,
  },
  data() {
    return {
      progress: 0,
      watchListTable: [
        {
          symbol: "NASDAQ:INTC",
          image: "",
          company_name: "Intel Corporation",
          last_price: 20.07,
          currency: "$",
          change: -14.87,
          change_pct: -2.64,
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style></style>
