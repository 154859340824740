<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="col-md-4 ml-auto mr-auto">
      <vnud-card class="card-login card-plain" noFooterLine>
        <template v-slot:header>
          <div>
            <div class="logo-container">
              <img src="img/gputop-logo-blackt.png" alt="" />
            </div>
          </div>
        </template>

        <div>
          <vee-input
            name="email"
            type="text"
            inputClasses="no-border form-control-lg"
            placeholder="Email or Username"
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="password"
            inputClasses="no-border form-control-lg"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />
        </div>

        <template v-slot:footer >
          <div>
            <n-button native-type="submit" type="primary" round block>
              Get Started
            </n-button>
            <div class="distance-footer">
              <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    Create Account
                  </router-link>
                </h6>
              </div>

              <div class="pull-right">
                <h6>
                  <a href="javascript:;" class="link footer-link">Need Help?</a>
                </h6>
              </div>
            </div>
          </div>
        </template>
      </vnud-card>
    </div>
  </Form>
</template>
<script>
import { VeeInput } from "@/components";
import { Form } from "vee-validate";
import { ref, getCurrentInstance } from "vue";

import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";

import * as Yup from "yup";
export default {
  name: "vnud-login",
  components: {
    VeeInput,
    Form,
  },
  methods: {},
  setup() {
    const { proxy } = getCurrentInstance();

    function onSubmit(values) {
      fetch(globalConfig.getApiPath("/user/login"), {
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status != "success") {
            utils.runToast(
              ` ${data.error_msg} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
            return;
          }

          localStorage.setItem("userToken", data.token);
          localStorage.setItem("userName", data.current_user);

          proxy.$store.dispatch("fetchUser");
          proxy.$router.push("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          utils.runToast(
            ` "There was a problem accessing this server" ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        });
    }

    const schema = Yup.object().shape({
      email: Yup.string().required().label("The Email or Username"),
      password: Yup.string().min(6).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
<style>
.distance-footer {
  margin-top: 10px;
}

.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
